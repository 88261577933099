import React, { useEffect } from "react";
import { gsap } from "gsap";
import classes from "./Login.module.scss";
import Navbar from "../Layout/Navbar/Navbar";
import { motion } from "framer-motion";

const slides = [
  { number: "$FGOLF" },
  { number: "$FGOLF" },
  { number: "$FGOLF" },
  { number: "$FGOLF" },
  { number: "$FGOLF" },
  { number: "$FGOLF" },
  { number: "$FGOLF" },
  { number: "$FGOLF" },
  { number: "$FGOLF" },
  { number: "$FGOLF" },
  { number: "$FGOLF" },
  { number: "$FGOLF" },
  { number: "$FGOLF" },
  { number: "$FGOLF" },
  { number: "$FGOLF" },
  { number: "$FGOLF" },
  { number: "$FGOLF" },
];

const Login = () => {
  return (
    <div className='min-vh-100 d-flex flex-column align-items-center justify-content-between'>
      <Navbar />
      <div
        className={`d-flex flex-column justify-content-center align-items-center ${classes.content}`}
      >
        <h1 className={classes.loginTitle}>Login with X</h1>
        <button className={`${classes.loginButton}`}>
          <p className={classes.buttonText}>Login with</p>
          <img
            src='/x-logo-white.png'
            alt='logo'
            className={`${classes.loginWithXImage} ${classes.defaultImage}`}
          />
          <img
            src='/x-logo-black.png'
            alt='logo'
            className={`${classes.loginWithXImage} ${classes.hoverImage}`}
          />
        </button>
        <img className={classes.coinImage} src='/f-coin.png' alt='logo' />
      </div>

      <div className={classes.scroll}>
        <div className={classes.mScroll}>
          {slides.map((slide, index) => (
            <span
              key={index}
              style={{
                fontWeight: `${index % 2 === 0 ? "bold" : "lighter"}`,
              }}
            >
              {slide.number}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Login;
