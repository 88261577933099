import React from "react";
import styles from "./LandingPage.module.scss";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.landingPage}>
      <div className={styles.logoSection}>
        <img className={styles.logo} src="/logo.png" alt="Logo" />
      </div>
      <div className={styles.header}>
        <div className={styles.buttonLeft}>
          <div className={styles.joinButton}>
            <div className={styles.socials}>
              <img
                className={styles.icon}
                src="/x.png"
                alt="X"
                onClick={() => {
                  window.open("https://x.com/fball_legends", "_blank");
                }}
              />

              <img
                className={styles.icon}
                src="/telegram.png"
                alt="Telegram"
                onClick={() => {
                  window.open("https://t.me/fball_legends", "_blank");
                }}
              />
              <img
                className={styles.icon}
                src="/discord.png"
                alt="Discord"
                onClick={() => {
                  window.open("https://discord.gg/fball-legends", "_blank");
                }}
              />
            </div>
            <span>Join!</span>
          </div>
        </div>

        <div className={styles.buttonRight}>
          <img
            src="/whitepaper.png"
            className={styles.whitepaperButton}
            alt="Whitepaper"
          />
        </div>
      </div>

      <div className={styles.content}>
        <h2 className={styles.title}>BECOME A FOOTBALL LEGEND!</h2>
        <div className={styles.descriptionContainer}>
          <p className={styles.description}>
            Football is Life: Start as an eager rookie, grow through challenges
            and seize your chance to become a legend; step onto the field
            and make history!
          </p>
        </div>
        <div className={styles.buttonGroup}>
          <button
            onClick={() => {
              window.open(
                "https://t.me/fball_legends_bot/fball?startapp",
                "_blank"
              );
            }}
            className={styles.playButton}
          >
            PLAY NOW{" "}
          </button>
          <button className={styles.airdropButton}>HOW TO PLAY</button>
        </div>
      </div>
      <div className={styles.footer}>
        <p className={styles.footerText}>
          © 2024 Football Legends. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default LandingPage;
