import React, { useEffect, useState } from "react";
import styles from "./Dashboard.module.scss"; // Import the SCSS module
import Navbar from "./Layout/Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { taskActions } from "../shared/slices/task-slice";
import Modal from "./Layout/UI/Modal/Modal";

const Dashboard = () => {
  const tasks = useSelector((state) => state.tasks.tasks);
  const [selectedTask, setSelectedTask] = useState({});
  const dispatct = useDispatch();

  //-------Modal tasks----------------

  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    console.log("Tasks: ", tasks);
  }, []);
  const clickTaskHandler = (e) => {
    if (e.isDone) {
      return;
    } else {
      setSelectedTask(e);
      setModalOpen(true);
      // window.open(e.link, "_blank");
      setTimeout(() => {
        dispatct(
          taskActions.updateTaskById({
            id: e.id,
            description: e.description,
            points: e.points,
            isDone: true,
          })
        );
      }, 8000);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const saveModal = () => {
    setModalOpen(false);
  };

  return (
    <div className={styles.container}>
      <Navbar />
      <div className={`row ${styles.content}`}>
        <Link to={"/admin-dashboard"}>Admin Dashboard</Link>
        <div className='col-md-6'>
          <div className={styles.card}>
            <h1 className={styles.cardTitle}>Referral Program</h1>
            <div
              className='card-body d-flex flex-column'
              style={{
                alignItems: "space-between",
                justifyContent: "space-between",
              }}
            >
              <p>
                Friends referred: <span className='fw-bold'>5</span>
              </p>
              <div className='input-group mb-3'>
                <input
                  type='text'
                  className={`form-control ${styles.formControl}`}
                  placeholder='Referral Code'
                />
                <button className={styles.referAFriendButton} type='button'>
                  Submit
                </button>
              </div>
              <button className={styles.referAFriendButton}>
                Refer a Friend
              </button>
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className={styles.card}>
            <div className={styles["scrollable-card"]}>
              <h1 className={styles.cardTitle}>Tasks</h1>
              <div className='card-body'>
                <ul className={styles.listGroup}>
                  {tasks.map((task, index) => (
                    //If the task is done, change the class name to 'listItemDone'
                    <li
                      key={index}
                      className={
                        task.isDone
                          ? `${styles.listItem} ${styles.passive}`
                          : styles.listItem
                      }
                      onClick={() => clickTaskHandler(task)}
                    >
                      <p className={styles.listItemText}>{task.description}</p>
                      <button className={styles.listItemDoneButton}>
                        +{task.points} points
                        <span className={styles.listItemIcon}>
                          {task.isDone && (
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color={"#28a745"}
                            />
                          )}
                        </span>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSave={saveModal}
        task={selectedTask}
      />
    </div>
  );
};

export default Dashboard;
