import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import LandingPage from "./pages/LandingPage";
import Login from "./components/auth/Login";
import NotFound404 from "./pages/NotFound404";
import Dashboard from "./components/Dashboard";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AdminDashboard from "./components/admin/AdminDashboard";

function App() {
  //Control the route of the application if route using routerdom is dashboard then make state true
  const user = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.isAuthenticated) {
      navigate("/dashboard");
    }
  }, [user]);

  return (
    <div>
      <Routes>
        <Route path='/' element={<LandingPage />} exact />
        <Route
          path='admin-dashboard'
          element={user.isAuthenticated ? <AdminDashboard /> : <Login />}
          exact
        />
        {/* Auth */}
        <Route path='/auth'>
          <Route path='login' element={<Login />} />
        </Route>

        <Route path='/dashboard' element={<Dashboard />} />

        {/* Add 404 */}
        <Route path='*' element={<NotFound404 />} />
      </Routes>
    </div>
  );
}

export default App;
