import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import classes from "./AdminDashboard.module.scss";
import { taskActions } from "../../shared/slices/task-slice";
import { info } from "autoprefixer";
const AdminDashboard = () => {
  const user = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispacth = useDispatch();

  useEffect(() => {
    if (!user.isAuthenticated) {
      navigate("/auth/login");
    }
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const task = {
      description: e.target.taskName.value,
      link: e.target.link.value,
      points: Number(e.target.points.value),
    };

    dispacth(taskActions.addTask(task));
    e.target.reset();
  };
  return (
    <div className={classes.container}>
      {/* Creade card on middle of page using bootstrap */}
      <div className='card text-center  w-75'>
        <div className='card-header'>
          <h2>Admin Dashboard</h2>
        </div>
        <Link to='/dashboard'>Dashboard</Link>
        <div className='card-body'>
          <form
            className=' d-flex align-items-center flex-column'
            onSubmit={handleSubmit}
          >
            <div className='mb-3'>
              <label htmlFor='taskName' className='form-label'>
                Task Name
              </label>
              <input
                type='text'
                className='form-control'
                id='taskName'
                aria-describedby='taskNameHelp'
              />
              <div id='taskNameHelp' className='form-text'>
                Please enter the task name
              </div>
            </div>
            <div className='mb-3'>
              <label htmlFor='link' className='form-label'>
                Link
              </label>
              <input type='url' className='form-control' id='link' />
            </div>
            <div className='mb-3'>
              <label htmlFor='taskName' className='form-label'>
                Points
              </label>
              <input
                type='number'
                className='form-control'
                id='points'
                aria-describedby='pointsHelp'
              />
              <div id='points' className='form-text'>
                Please enter the points
              </div>
            </div>
            <button type='submit' className='btn btn-primary'>
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
