import React from "react";

const Navbar = () => {
  return (
    <div>
      <nav className='navbar navbar-expand-lg fixed-top navbar-light p-3'>
        <a className='navbar-brand' href='#'>
          <img src='/logo.png' alt='logo' style={{ width: "150px" }} />
        </a>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarNav'
          aria-controls='navbarNav'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
      </nav>
    </div>
  );
};

export default Navbar;
