import React, { useEffect } from "react";
import classes from "./Modal.module.scss";

const Modal = ({ isOpen, onClose, onSave, task }) => {
  useEffect(() => {
    console.log("Task: ", task);
  }, [task]);
  return (
    <div>
      <div className={`${classes.modal} ${isOpen ? classes.show : ""}`}>
        <div
          className={`${classes.overlay} ${isOpen ? classes.show : ""}`}
          onClick={onClose}
        ></div>
        <div className={classes.modalContent}>
          <span className={classes.close} onClick={onClose}>
            &times;
          </span>
          <div className={classes.content}>
            <h1>{task.description}</h1>
            <p>{task.points} points</p>
            <button className={classes.saveButton} onClick={onSave}>
              Execute
            </button>
            <button className={classes.collectRewardButton}>
              Collect Reward
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
