import { createSlice } from "@reduxjs/toolkit";

const initialTasksState = {
  tasks: [
    {
      id: 1,
      description:
        "Complete asdas dasdass sssss sssssas ddddddd ddd dddddddddddddd ddd ",
      points: 10,
      isDone: false,
    },
    {
      id: 2,
      description: "Verify email",
      points: 5,
      link: "",
      isDone: false,
    },
    {
      id: 3,
      description: "Connect social media",
      points: 8,
      link: "",
      isDone: false,
    },
    {
      id: 4,
      description: "Add a profile picture",
      points: 6,
      link: "",
      isDone: false,
    },
    {
      id: 5,
      description: "Update your bio",
      points: 4,
      link: "",
      isDone: false,
    },
    {
      id: 6,
      description: "Join a group",
      points: 7,
      link: "",
      isDone: false,
    },
    {
      id: 7,
      description: "Follow 5 people",
      points: 9,
      link: "",
      isDone: false,
    },
    {
      id: 8,
      description: "Post your first update",
      points: 5,
      link: "",
      isDone: true,
    },
    {
      id: 9,
      description: "Like 10 posts",
      points: 3,
      link: "",
      isDone: true,
    },
    {
      id: 10,
      description: "Comment on a post",
      points: 2,
      link: "",
      isDone: true,
    },
    // Add more tasks as needed
  ],
  loading: false,
  error: null,
};

const taskSlice = createSlice({
  name: "tasks",
  initialState: initialTasksState,
  reducers: {
    // Task Actions---------------------------------------------
    addTask: (state, action) => {
      //Set id as the last task id + 1
      const id = state.tasks[state.tasks.length - 1].id + 1;
      const newTask = {
        id,
        description: action.payload.description,
        link: action.payload.link,
        points: action.payload.points,
        isDone: false,
      };
      state.tasks.push(newTask);
    },
    // addTaskSuccess: (state, action) => {
    // state.loading = false;
    // state.error = null;
    // state.tasks.push(action.payload);
    // },
    // addTaskFail: (state, action) => {
    // state.loading = false;
    // state.error = action.payload;
    // },
    deleteTaskById: (state, action) => {
      state.tasks = state.tasks.filter((task) => task.id !== action.payload);
    },
    updateTaskById: (state, action) => {
      const { id, description, points, isDone } = action.payload;
      const existingTask = state.tasks.find((task) => task.id === id);
      if (existingTask) {
        existingTask.description = description;
        existingTask.points = points;
        existingTask.isDone = isDone;
      }
    },
    // Add more actions as needed
  },
});

export const taskActions = taskSlice.actions;

export default taskSlice.reducer;
