import { configureStore } from "@reduxjs/toolkit";

import authReducer from "../shared/slices/auth-slice";
import taskReducer from "../shared/slices/task-slice";
const store = configureStore({
  // We can use the reducer directly or we can use the slice reducer
  // It changes the way we import the reducers
  reducer: {
    auth: authReducer,
    tasks: taskReducer,
  },
  //False serialize the state
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
