import React from "react";
import classes from "./NotFound404.module.scss";

const NotFound404 = () => {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center min-vh-100 '>
      <div
        className='d-flex  justify-content-center align-items-center text-white '
        style={{ height: "10vh" }}
      >
        <h1 className={classes.NotFound404}>404</h1>
        <h2 className={classes.NotFound404Text}>Page Not Found</h2>
      </div>
      <button className='btn btn-primary mt-5 '>
        <a href='/' className='text-white'>
          Back To Home
        </a>
      </button>
    </div>
  );
};

export default NotFound404;
